.console {
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 240px;
  overflow-y: auto;
  padding: 8px 0;
}

.console::-webkit-scrollbar {
  width: 8px;
}

.console::-webkit-scrollbar-track {
  background: #141B41;
}

.console::-webkit-scrollbar-thumb {
  background: #4E62C0;
  border-radius: 4px;
}

.console::-webkit-scrollbar-thumb:hover {
  background: #2440be;
}

.leftConsole {
  position: absolute;
  bottom: -240px;
  left: 0;
  width: 49%;
  height: 100%;
  max-height: 240px;
  overflow-y: auto;
  padding: 8px 0;
}

.rightConsole {
  position: absolute;
  bottom: -240px;
  left: 51%;
  width: 49%;
  height: 100%;
  max-height: 240px;
  overflow-y: auto;
  padding: 8px 0;
}

.leftConsole::-webkit-scrollbar {
  width: 8px;
}

.leftConsole::-webkit-scrollbar-track {
  background: #141B41;
}

.leftConsole::-webkit-scrollbar-thumb {
  background: #4E62C0;
  border-radius: 4px;
}

.leftConsole::-webkit-scrollbar-thumb:hover {
  background: #2440be;
}

.rightConsole::-webkit-scrollbar {
  width: 8px;
}

.rightConsole::-webkit-scrollbar-track {
  background: #141B41;
}

.rightConsole::-webkit-scrollbar-thumb {
  background: #4E62C0;
  border-radius: 4px;
}

.rightConsole::-webkit-scrollbar-thumb:hover {
  background: #2440be;
}
