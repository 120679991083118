* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(219.91deg, #08134A 17.08%, #1B2660 56.45%, #2E3B7E 98.66%);
  position: relative;
}

body:before {
  content: '';
  display: block;
  position: absolute;
  pointer-events: none;
  background: url(../public/1.png) no-repeat;
  background-position: top left;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
  opacity: .2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button,
textarea {
  border: 0;
  outline: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.greenBg {
  background-color: green !important;
  box-shadow: 0 0 12px #4e62c0;
}

.loader {
  width: 100%;
}
